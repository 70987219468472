import { EMBED_COMPONENT_TYPES } from '../constants/embedComponentTypes';
import { ADHOC_EDITOR_CODE } from '../constants/liveCodeScripts/adHocEditorScripts';
import { METRIC_SET_CODE } from '../constants/liveCodeScripts/metricSetScripts';
import {
  EMBED_VIEWER_DASHBOARD_JS_CODE,
  EMBED_VIEWER_REPORT_JS_CODE,
  EMBED_VIEWER_SCORECARD_JS_CODE,
} from '../constants/liveCodeScripts/embedViewerScripts';

import {
  CREATE_COMPONENT_JS_CODE,
  CREATE_SECOND_DASHBOARD_ASYNC,
  CREATE_SECOND_DASHBOARD_AWAIT,
  EMBED_COMPONENT_CLEANUP_JS_CODE_1,
  EMBED_COMPONENT_CLEANUP_JS_CODE_2,
} from '../constants/liveCodeScripts/commonCode';
import {
  DASHBOARDS_LISTING_CSS_CODE,
  DASHBOARD_BUILDER_COMPONENT_CONFIG,
  DASHBOARD_LISTING_HTML_CODE,
  DASHBOARD_LISTING_JS_CODE,
} from '../constants/liveCodeScripts/dashboardBuilderScripts';
import {
  CREATE_SECOND_DASHBOARD_JS_CODE,
  DASHBOARD_COMPONENT_CONFIG_JS_CODE,
  DOUBLE_DASHBOARDS_FIRST_DASHBOARD_CONFIG_JS_CODE,
  SECOND_DASHBOARD_HTML_CODE,
} from '../constants/liveCodeScripts/dashboardScripts';
import {
  DASHBOARD_IP_OVERRIDES_SETTING,
  VISUAL_BUILDER_IP_OVERRIDES_SETTING,
  DASHBOARD_BUILDER_IP_OVERRIDES_SETTING,
  getInventoryIpOverridesSetting,
  SOURCE_EDITOR_IP_OVERRIDES_SETTING,
} from '../constants/liveCodeScripts/interactivityProfileScripts';
import {
  PUBLISH_HTML_CODE,
  PUBLISH_JS_CODE,
  PUBLISH_STYLES,
  PUB_SUB_COMMON_CODE,
  SUBSCRIBE_JS_CODE,
  SUBSCRIBE_HTML_CODE,
  SUBSCRIBE_STYLES,
  PUB_SUB_TOGGLE_STYLE,
} from '../constants/liveCodeScripts/pubSubScripts';
import {
  COMPONENTS_CONFIG,
  CUSTOM_CELL_RENDERER,
  CUSTOMIZE_ACTION_BUTTONS,
  CUSTOMIZE_ACTION_BUTTONS_CSS_CODE,
  CUSTOMIZE_ACTION_BUTTONS_HELPERS,
  CUSTOMIZE_ACTION_BUTTONS_HTML_CODE,
  CUSTOMIZE_FILTER_BUTTONS,
  CUSTOMIZE_INVENTORY_TABLE,
  CUSTOMIZE_SEARCH_CONTROLLERS,
  INVENTORY_CONFIG,
} from '../constants/liveCodeScripts/inventoryScripts';
import {
  EMBED_SOURCE_JS_CODE,
  VISUAL_AUTHORING_BREADCRUMBS_CODE,
  VISUAL_BUILDER_COMPONENT_CONFIG,
  VISUAL_LISTING_CSS_CODE,
  VISUAL_LISTING_HTML_CODE,
  VISUAL_LISTING_JS_CODE,
} from '../constants/liveCodeScripts/visualBuilderScripts';
import {
  INVENTORY_EVENT_LISTENERS_CODE,
  INVENTORY_EVENT_LISTENERS_DELETE_CODE,
  INVENTORY_EVENT_LISTENERS_FAIL_CODE,
  INVENTORY_EVENT_LISTENERS_READY_CODE,
} from '../constants/liveCodeScripts/eventListenerScripts';
import {
  DASHBOARD_EVENT_LISTENER_CHANGED_CODE,
  DASHBOARD_EVENT_LISTENER_DELETED_CODE,
  DASHBOARD_EVENT_LISTENER_DOC_FAIL_CODE,
  DASHBOARD_EVENT_LISTENER_DOC_UNAUTHORIZED_CODE,
  DASHBOARD_EVENT_LISTENER_FAILED_CODE,
  DASHBOARD_EVENT_LISTENER_READY_CODE,
  DASHBOARD_EVENT_LISTENER_SAVE_CODE,
  DASHBOARD_EVENT_LISTENERS_CODE,
} from '../constants/liveCodeScripts/dashboardEventListenerScripts';
import {
  DASHBOARD_EVENT_LISTENER_VISUAL_ADDED_CODE,
  DASHBOARD_EVENT_LISTENER_VISUAL_FAIL_CODE,
  DASHBOARD_EVENT_LISTENER_VISUAL_LOAD_CODE,
  DASHBOARD_EVENT_LISTENER_VISUAL_REMOVED_CODE,
  DASHBOARD_EVENT_LISTENER_VISUAL_RENDERED_CODE,
  DASHBOARD_EVENT_LISTENER_VISUAL_SAVE_CODE,
  VISUAL_EVENT_LISTENERS_CODE,
} from '../constants/liveCodeScripts/visualEventListenerScripts';
import {
  DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_CHANGE_CODE,
  DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_FAILED_CODE,
  DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_LOAD_CODE,
  DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_READY_CODE,
} from '../constants/liveCodeScripts/visualBuilderEventListenerScripts';
import {
  SOURCE_EDITOR_COMPONENT_CONFIG,
  SOURCE_EDITOR_EVENT_LISTENERS_CODE,
} from '../constants/liveCodeScripts/sourceEditorScripts';
import { CSS_DUNDAS, HTML_DUNDAS, JS_DUNDAS } from '../constants/liveCodeScripts/dundasScripts';

import { CSS_COMPOSER, HTML_COMPOSER, JS_COMPOSER } from '../constants/liveCodeScripts/composerScripts';

import { THEMES_CODE } from '../constants/liveCodeScripts/commonCode';

export const applySettingsToJsCode = (
  template,
  settings,
  embedComponentType,
  dashboardId,
  originId,
  firstDashboardId,
  sourceId,
  defaultUserSessionId
) => {
  return (
    template
      .replaceAll(
        '%jsCode%',
        embedComponentType === EMBED_COMPONENT_TYPES.adHocEditor ||
          embedComponentType === EMBED_COMPONENT_TYPES.embedViewer ||
          embedComponentType === EMBED_COMPONENT_TYPES.metricSet
          ? JS_DUNDAS
          : JS_COMPOSER
      )
      .replaceAll(
        '%htmlCode%',
        embedComponentType === EMBED_COMPONENT_TYPES.adHocEditor ||
          embedComponentType === EMBED_COMPONENT_TYPES.embedViewer ||
          embedComponentType === EMBED_COMPONENT_TYPES.metricSet
          ? HTML_DUNDAS
          : HTML_COMPOSER
      )
      .replaceAll(
        '%cssCode%',
        embedComponentType === EMBED_COMPONENT_TYPES.adHocEditor ||
          embedComponentType === EMBED_COMPONENT_TYPES.embedViewer ||
          embedComponentType === EMBED_COMPONENT_TYPES.metricSet
          ? CSS_DUNDAS
          : CSS_COMPOSER
      )
      .replaceAll(
        '%embedComponentType%',
        embedComponentType === EMBED_COMPONENT_TYPES.embedViewer
          ? settings.ViewType === 'dashboard'
            ? EMBED_VIEWER_DASHBOARD_JS_CODE
            : settings.ViewType === 'report'
            ? EMBED_VIEWER_REPORT_JS_CODE
            : EMBED_VIEWER_SCORECARD_JS_CODE
          : embedComponentType === EMBED_COMPONENT_TYPES.adHocEditor
          ? ADHOC_EDITOR_CODE
          : embedComponentType == EMBED_COMPONENT_TYPES.metricSet
          ? METRIC_SET_CODE
          : ''
      )
      .replaceAll(
        '%componentConfig%',
        embedComponentType === EMBED_COMPONENT_TYPES.dashboard
          ? settings.doubleDashboards
            ? DOUBLE_DASHBOARDS_FIRST_DASHBOARD_CONFIG_JS_CODE
            : DASHBOARD_COMPONENT_CONFIG_JS_CODE
          : embedComponentType === EMBED_COMPONENT_TYPES.visualBuilder
          ? VISUAL_BUILDER_COMPONENT_CONFIG
          : embedComponentType === EMBED_COMPONENT_TYPES.dashboardBuilder
          ? DASHBOARD_BUILDER_COMPONENT_CONFIG
          : embedComponentType === EMBED_COMPONENT_TYPES.inventory
          ? INVENTORY_CONFIG
          : SOURCE_EDITOR_COMPONENT_CONFIG
      )
      .replaceAll('%themesCode%', THEMES_CODE)
      .replaceAll('%embedThemeId%', settings.theme)
      .replaceAll('%sessionId%', defaultUserSessionId)
      .replaceAll('%toolbarHidden%', settings.toolbarHidden)
      .replaceAll('%toolbarAddMetricsSetHidden%', settings.toolbarAddMetricsSetHidden)
      .replaceAll('%toolbarAddDashboardHidden%', settings.toolbarAddDashboardHidden)
      .replaceAll('%toolbarAddReportHidden%', settings.toolbarAddReportHidden)
      .replaceAll('%dockablesHidden%', settings.dockablesHidden)
      .replaceAll('%openExistingHidden%', settings.openExistingHidden)
      .replaceAll('%theme%', settings.theme)
      .replaceAll('%embedDashboardId%', settings.dashboardId)
      .replaceAll('%embedReportId%', settings.reportId)
      .replaceAll('%embedScorecardId%', settings.scorecardId)
      .replaceAll('%embedMetricSetId%', settings.metricSetId)

      // Composer Variables
      .replaceAll(
        '%createComponent%',
        !settings.listVisuals && !settings.listDashboards ? CREATE_COMPONENT_JS_CODE : ''
      )
      .replaceAll('%createSecondDashboard%', settings.doubleDashboards ? CREATE_SECOND_DASHBOARD_JS_CODE : '')
      .replaceAll('%createSecondDashboardAsync%', settings.doubleDashboards ? CREATE_SECOND_DASHBOARD_ASYNC : '')
      .replaceAll('%createSecondDashboardAwait%', settings.doubleDashboards ? CREATE_SECOND_DASHBOARD_AWAIT : '')
      .replaceAll('%showTitle%', settings.showTitle)
      .replaceAll('%showHeader%', settings.showHeader)
      .replaceAll('%inventoryItem%', settings.inventoryItem)
      .replaceAll('%showActions%', settings.showActions)
      .replaceAll('%ip%', settings.ip)
      .replaceAll('%themeComposer%', settings.themeComposer)
      .replaceAll('%dashboardId%', dashboardId)
      .replaceAll('%originId%', originId)
      .replaceAll('%dashboardId2%', firstDashboardId)
      .replaceAll('%sourceId%', sourceId)
      .replaceAll(
        '%ipOverrides%',
        settings.dashboardIpOverrides && embedComponentType === EMBED_COMPONENT_TYPES.dashboard
          ? DASHBOARD_IP_OVERRIDES_SETTING
          : settings.dashboardBuilderIpOverrides && embedComponentType === EMBED_COMPONENT_TYPES.dashboardBuilder
          ? DASHBOARD_BUILDER_IP_OVERRIDES_SETTING
          : settings.visualIpOverrides && embedComponentType === EMBED_COMPONENT_TYPES.visualBuilder
          ? VISUAL_BUILDER_IP_OVERRIDES_SETTING
          : settings.sourceEditorIpOverrides && embedComponentType === EMBED_COMPONENT_TYPES.sourceEditor
          ? SOURCE_EDITOR_IP_OVERRIDES_SETTING
          : settings.inventoryIpOverrides
          ? getInventoryIpOverridesSetting(settings.inventoryItem)
          : ''
      )
      .replaceAll('%editorPlacement%', settings.editorPlacement)
      .replaceAll('%breadcrumbs%', settings.breadcrumbs ? VISUAL_AUTHORING_BREADCRUMBS_CODE : '')
      .replaceAll('%dashboardEventListeners%', settings.dashboardEventListeners ? DASHBOARD_EVENT_LISTENERS_CODE : '')
      .replaceAll(
        '%sourceEditorEventListeners%',
        settings.sourceEditorEventListeners ? SOURCE_EDITOR_EVENT_LISTENERS_CODE : ''
      )
      .replaceAll('%dbEventListener%', settings.dbEventListener ? DASHBOARD_EVENT_LISTENERS_CODE : '')
      .replaceAll('%visualEventListener%', settings.visualEventListener ? VISUAL_EVENT_LISTENERS_CODE : '')
      .replaceAll(
        '%dashboardReadyEventListener%',
        settings.dashboardReadyEventListener ? DASHBOARD_EVENT_LISTENER_READY_CODE : ''
      )
      .replaceAll(
        '%dashboardChangedEventListener%',
        settings.dashboardChangedEventListener ? DASHBOARD_EVENT_LISTENER_CHANGED_CODE : ''
      )
      .replaceAll(
        '%dashboardDeleteEventListener%',
        settings.dashboardDeleteEventListener ? DASHBOARD_EVENT_LISTENER_DELETED_CODE : ''
      )
      .replaceAll(
        '%dashboardLoadFailEventListener%',
        settings.dashboardLoadFailEventListener ? DASHBOARD_EVENT_LISTENER_FAILED_CODE : ''
      )
      .replaceAll(
        '%dashboardSaveEventListener%',
        settings.dashboardSaveEventListener ? DASHBOARD_EVENT_LISTENER_SAVE_CODE : ''
      )
      .replaceAll(
        '%dashboardVisualAddEventListener%',
        settings.dashboardVisualAddEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_ADDED_CODE : ''
      )
      .replaceAll(
        '%dashboardVisualRemoveEventListener%',
        settings.dashboardVisualRemoveEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_REMOVED_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderReadyEventListener%',
        settings.dashboardBuilderReadyEventListener ? DASHBOARD_EVENT_LISTENER_READY_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderChangedEventListener%',
        settings.dashboardBuilderChangedEventListener ? DASHBOARD_EVENT_LISTENER_CHANGED_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderDeleteEventListener%',
        settings.dashboardBuilderDeleteEventListener ? DASHBOARD_EVENT_LISTENER_DELETED_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderLoadFailEventListener%',
        settings.dashboardBuilderLoadFailEventListener ? DASHBOARD_EVENT_LISTENER_FAILED_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderSaveEventListener%',
        settings.dashboardBuilderSaveEventListener ? DASHBOARD_EVENT_LISTENER_SAVE_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderVisualAddEventListener%',
        settings.dashboardBuilderVisualAddEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_ADDED_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderVisualRemoveEventListener%',
        settings.dashboardBuilderVisualRemoveEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_REMOVED_CODE : ''
      )
      .replaceAll(
        '%visualLoadEventListener%',
        settings.visualLoadEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_LOAD_CODE : ''
      )
      .replaceAll(
        '%visualLoadFailEventListener%',
        settings.visualLoadFailEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_FAIL_CODE : ''
      )
      .replaceAll(
        '%visualRenderEventListener%',
        settings.visualRenderEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_RENDERED_CODE : ''
      )
      .replaceAll(
        '%visualSaveEventListener%',
        settings.visualSaveEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_SAVE_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderVisualLoadEventListener%',
        settings.dashboardBuilderVisualLoadEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_LOAD_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderVisualLoadFailEventListener%',
        settings.dashboardBuilderVisualLoadFailEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_FAIL_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderVisualRenderEventListener%',
        settings.dashboardBuilderVisualRenderEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_RENDERED_CODE : ''
      )
      .replaceAll(
        '%dashboardBuilderVisualSaveEventListener%',
        settings.dashboardBuilderVisualSaveEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_SAVE_CODE : ''
      )
      .replaceAll('%documentFailed%', settings.documentFailed ? DASHBOARD_EVENT_LISTENER_DOC_FAIL_CODE : '')
      .replaceAll(
        '%dashboardBuilderDocumentFailed%',
        settings.dashboardBuilderDocumentFailed ? DASHBOARD_EVENT_LISTENER_DOC_FAIL_CODE : ''
      )
      .replaceAll(
        '%visualBuilderChange%',
        settings.visualBuilderChange ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_CHANGE_CODE : ''
      )
      .replaceAll(
        '%visualBuilderFail%',
        settings.visualBuilderFail ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_FAILED_CODE : ''
      )
      .replaceAll(
        '%visualBuilderLoad%',
        settings.visualBuilderLoad ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_LOAD_CODE : ''
      )
      .replaceAll(
        '%visualBuilderReady%',
        settings.visualBuilderReady ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_READY_CODE : ''
      )

      .replaceAll(
        '%dbVisualBuilderChange%',
        settings.dbVisualBuilderChange ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_CHANGE_CODE : ''
      )
      .replaceAll(
        '%dbVisualBuilderFail%',
        settings.dbVisualBuilderFail ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_FAILED_CODE : ''
      )
      .replaceAll(
        '%dbVisualBuilderLoad%',
        settings.dbVisualBuilderLoad ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_LOAD_CODE : ''
      )
      .replaceAll(
        '%dbVisualBuilderReady%',
        settings.dbVisualBuilderReady ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_READY_CODE : ''
      )

      .replaceAll('%unauthorized%', settings.unauthorized ? DASHBOARD_EVENT_LISTENER_DOC_UNAUTHORIZED_CODE : '')
      .replaceAll(
        '%dashboardBuilderUnauthorized%',
        settings.dashboardBuilderUnauthorized ? DASHBOARD_EVENT_LISTENER_DOC_UNAUTHORIZED_CODE : ''
      )
      .replaceAll(
        '%vbVisualLoadEventListener%',
        settings.vbVisualLoadEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_LOAD_CODE : ''
      )
      .replaceAll(
        '%vbVisualLoadFailEventListener%',
        settings.vbVisualLoadFailEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_FAIL_CODE : ''
      )
      .replaceAll(
        '%vbVisualRenderEventListener%',
        settings.vbVisualRenderEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_RENDERED_CODE : ''
      )
      .replaceAll(
        '%vbVisualSaveEventListener%',
        settings.vbVisualSaveEventListener ? DASHBOARD_EVENT_LISTENER_VISUAL_SAVE_CODE : ''
      )
      .replaceAll(
        '%vbVisualBuilderChange%',
        settings.vbVisualBuilderChange ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_CHANGE_CODE : ''
      )
      .replaceAll(
        '%vbVisualBuilderFail%',
        settings.vbVisualBuilderFail ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_FAILED_CODE : ''
      )
      .replaceAll(
        '%vbVisualBuilderReady%',
        settings.vbVisualBuilderReady ? DASHBOARD_EVENT_LISTENER_VISUAL_BUILDER_READY_CODE : ''
      )
      .replaceAll('%inventoryEventListeners%', settings.inventoryEventListeners ? INVENTORY_EVENT_LISTENERS_CODE : '')
      .replaceAll(
        '%inventoryReadyEventListener%',
        settings.inventoryReadyEventListener ? INVENTORY_EVENT_LISTENERS_READY_CODE : ''
      )
      .replaceAll(
        '%inventoryFailEventListener%',
        settings.inventoryFailEventListener ? INVENTORY_EVENT_LISTENERS_FAIL_CODE : ''
      )
      .replaceAll(
        '%inventoryDeleteEventListener%',
        settings.inventoryDeleteEventListener ? INVENTORY_EVENT_LISTENERS_DELETE_CODE : ''
      )
      .replaceAll('%embedSource%', settings.embedSource ? EMBED_SOURCE_JS_CODE : '')
      .replaceAll(
        '%componentType%',
        embedComponentType === EMBED_COMPONENT_TYPES.visualBuilder
          ? '"visual-builder"'
          : embedComponentType === EMBED_COMPONENT_TYPES.inventory
          ? '"inventory"'
          : embedComponentType === EMBED_COMPONENT_TYPES.sourceEditor
          ? '"source-editor"'
          : settings.embedInLiteMode
          ? '"lite-dashboard"'
          : '"dashboard"'
      )
      .replaceAll('%listVisuals%', settings.listVisuals ? VISUAL_LISTING_JS_CODE : '')
      .replaceAll('%listDashboards%', settings.listDashboards ? DASHBOARD_LISTING_JS_CODE : '')
      .replaceAll(
        '%componentCleanup1%',
        settings.listVisuals || settings.listDashboards ? EMBED_COMPONENT_CLEANUP_JS_CODE_1 : ''
      )
      .replaceAll(
        '%pubSubCommonJsCode%',
        settings.crossVisualFiltersSubscribe || settings.crossVisualFiltersPublish ? PUB_SUB_COMMON_CODE : ''
      )
      .replaceAll('%subscribeCode%', settings.crossVisualFiltersSubscribe ? SUBSCRIBE_JS_CODE : '')
      .replaceAll('%publishCode%', settings.crossVisualFiltersPublish ? PUBLISH_JS_CODE : '')
      .replaceAll(
        '%componentCleanup2%',
        settings.listVisuals || settings.listDashboards ? EMBED_COMPONENT_CLEANUP_JS_CODE_2 : ''
      )

      .replaceAll('%refresh%', settings.refresh)
      .replaceAll('%changeLayout%', settings.changeLayout)
      .replaceAll('%rename%', settings.rename)
      .replaceAll('%shareFilterSets%', settings.shareFilterSets)
      .replaceAll('%dashboardInteractions%', settings.dashboardInteractions)
      .replaceAll('%addToFavourites%', settings.addToFavourites)
      .replaceAll('%delete%', settings.delete)
      .replaceAll('%filter%', settings.filter)
      .replaceAll('%manageTags%', settings.manageTags)
      .replaceAll('%manageAlerts%', settings.manageAlerts)
      .replaceAll('%shareDashboard%', settings.shareDashboard)
      .replaceAll('%scheduleReports%', settings.scheduleReports)
      .replaceAll('%textSnippet%', settings.textSnippet)
      .replaceAll('%filterSnippet%', settings.filterSnippet)
      .replaceAll('%widgetSettings%', settings.widgetSettings)
      .replaceAll('%comments%', settings.comments)
      .replaceAll('%exportPngPdf%', settings.exportPngPdf)
      .replaceAll('%addVisuals%', settings.addVisuals)
      .replaceAll('%exportConfiguration%', settings.exportConfiguration)
      .replaceAll('%dashboardLinks%', settings.dashboardLinks)
      .replaceAll('%save%', settings.save)
      .replaceAll('%actionsAction%', settings.actionsAction)
      .replaceAll('%rulers%', settings.rulers)
      .replaceAll('%zoomAction%', settings.zoomAction)
      .replaceAll('%filterAction%', settings.filterAction)
      .replaceAll('%colors%', settings.colors)
      .replaceAll('%metrics%', settings.metrics)
      .replaceAll('%actions%', settings.actions)
      .replaceAll('%trendAction%', settings.trendAction)
      .replaceAll('%visualStyle%', settings.visualStyle)
      .replaceAll('%keysetAction%', settings.keysetAction)
      .replaceAll('%keyset%', settings.keyset)
      .replaceAll('%copy%', settings.copy)
      .replaceAll('%settings%', settings.settings)
      .replaceAll('%export%', settings.export)
      .replaceAll('%timebarPanel%', settings.timebarPanel)
      .replaceAll('%detailsAction%', settings.detailsAction)
      .replaceAll('%maximize%', settings.maximize)
      .replaceAll('%linkAction%', settings.linkAction)
      .replaceAll('%filter%', settings.filter)
      .replaceAll('%remove%', settings.remove)
      .replaceAll('%grouping%', settings.grouping)
      .replaceAll('%rename%', settings.rename)
      .replaceAll('%sort%', settings.sort)
      .replaceAll('%timebarField%', settings.timebarField)
      .replaceAll('%saveAs%', settings.saveAs)
      .replaceAll('%conditionalFormatting%', settings.conditionalFormatting)
      .replaceAll('%info%', settings.info)
      .replaceAll('%addNew%', settings.addNew)
      .replaceAll('%import%', settings.import)
      .replaceAll('%permissions%', settings.permissions)
      .replaceAll('%description%', settings.description)
      .replaceAll('%tags%', settings.tags)
      .replaceAll('%share%', settings.share)
      .replaceAll('%multiSelect%', settings.multiSelect)
      .replaceAll('%rowSecurity%', settings.rowSecurity)
      .replaceAll('%columnSecurity%', settings.columnSecurity)
      .replaceAll('%clearCache%', settings.clearCache)
      .replaceAll('%availableVisualTypes%', settings.availableVisualTypes)
      .replaceAll('%schedule%', settings.schedule)
      .replaceAll('%favorites%', settings.favorites)
      .replaceAll('%sourceEditorActiveTab%', settings.sourceEditorActiveTab)
      .replaceAll('%showCreateTab%', settings.showCreateTab)
      .replaceAll('%showFieldsTab%', settings.showFieldsTab)
      .replaceAll('%showCacheTab%', settings.showCacheTab)
      .replaceAll('%showSettingsTab%', settings.showSettingsTab)
      .replaceAll('%addFromConnection%', settings.addFromConnection)
      .replaceAll('%addFromFileUpload%', settings.addFromFileUpload)
      .replaceAll('%selectFileUpload%', settings.selectFileUpload)
      .replaceAll('%uploadnewFile%', settings.uploadnewFile)
      .replaceAll('%createJoins%', settings.createJoins)
      .replaceAll('%filterValuesEntities%', settings.filterValuesEntities)
      .replaceAll('%exportSource%', settings.exportSource)
      .replaceAll('%copySource%', settings.copySource)
      .replaceAll('%addDerivedField%', settings.addDerivedField)
      .replaceAll('%addHierarchyField%', settings.addHierarchyField)
      .replaceAll('%addCustomMetric%', settings.addCustomMetric)
      .replaceAll('%visibility%', settings.visibility)
      .replaceAll('%fieldCapabilities%', settings.fieldCapabilities)
      .replaceAll('%filterValueOverrides%', settings.filterValueOverrides)
      .replaceAll('%manageTranslations%', settings.manageTranslations)
      .replaceAll('%setUniqueKey%', settings.setUniqueKey)
      .replaceAll('%dataCache%', settings.dataCache)
      .replaceAll('%scheduleRefresh%', settings.scheduleRefresh)
      .replaceAll('%statisticsCache%', settings.statisticsCache)
      .replaceAll('%fieldSettings%', settings.fieldSettings)

      .replaceAll(
        '%componentsConfig%',
        settings.customizeActionButtons ||
          settings.customizeSearchControllers ||
          settings.customizeFilterButtons ||
          settings.customizeInventoryTable
          ? COMPONENTS_CONFIG
          : ''
      )
      .replaceAll('%customizeActionButtons%', settings.customizeActionButtons ? CUSTOMIZE_ACTION_BUTTONS : '')
      .replaceAll(
        '%customizeActionButtonsHelpers%',
        settings.customizeActionButtons ? CUSTOMIZE_ACTION_BUTTONS_HELPERS : ''
      )
      .replaceAll(
        '%customizeSearchControllers%',
        settings.customizeSearchControllers ? CUSTOMIZE_SEARCH_CONTROLLERS : ''
      )
      .replaceAll('%customizeFilterButtons%', settings.customizeFilterButtons ? CUSTOMIZE_FILTER_BUTTONS : '')
      .replaceAll('%customCellRenderer%', settings.customizeInventoryTable ? CUSTOM_CELL_RENDERER : '')
      .replaceAll('%customizeInventoryTable%', settings.customizeInventoryTable ? CUSTOMIZE_INVENTORY_TABLE : '')
  );
};

export const applySettingsToHtmlCode = (template, settings, embedComponentType) =>
  template
    .replaceAll(
      '%htmlCode%',
      embedComponentType === EMBED_COMPONENT_TYPES.adHocEditor ||
        embedComponentType === EMBED_COMPONENT_TYPES.embedViewer ||
        embedComponentType === EMBED_COMPONENT_TYPES.metricSet
        ? HTML_DUNDAS
        : HTML_COMPOSER
    )
    .replaceAll('%createSecondDashboard%', settings.doubleDashboards ? SECOND_DASHBOARD_HTML_CODE : '')
    .replaceAll('%customizeActionButtons%', settings.customizeActionButtons ? CUSTOMIZE_ACTION_BUTTONS_HTML_CODE : '')
    .replaceAll('%publishCode%', settings.crossVisualFiltersPublish ? PUBLISH_HTML_CODE : '')
    .replaceAll('%subscribeCode%', settings.crossVisualFiltersSubscribe ? SUBSCRIBE_HTML_CODE : '')
    .replaceAll('%listVisuals%', settings.listVisuals ? VISUAL_LISTING_HTML_CODE : '')
    .replaceAll('%listDashboards%', settings.listDashboards ? DASHBOARD_LISTING_HTML_CODE : '');

export const applySettingsToCssCode = (template, settings, embedComponentType) =>
  template
    .replaceAll(
      '%cssCode%',
      embedComponentType === EMBED_COMPONENT_TYPES.adHocEditor ||
        embedComponentType === EMBED_COMPONENT_TYPES.embedViewer ||
        embedComponentType === EMBED_COMPONENT_TYPES.metricSet
        ? CSS_DUNDAS
        : CSS_COMPOSER
    )
    .replaceAll('%listDashboards%', settings.listDashboards ? DASHBOARDS_LISTING_CSS_CODE : '')
    .replaceAll('%customizeActionButtons%', settings.customizeActionButtons ? CUSTOMIZE_ACTION_BUTTONS_CSS_CODE : '')
    .replaceAll('%listVisuals%', settings.listVisuals ? VISUAL_LISTING_CSS_CODE : '')
    .replaceAll('%mainFlexDirection%', settings.listVisuals || settings.listDashboards ? 'row' : 'column')
    .replaceAll('%publishStyles%', settings.crossVisualFiltersPublish ? PUBLISH_STYLES : '')
    .replaceAll(
      '%subscribeStyles%',
      settings.crossVisualFiltersPublish && settings.crossVisualFiltersSubscribe
        ? PUB_SUB_TOGGLE_STYLE
        : settings.crossVisualFiltersSubscribe
        ? SUBSCRIBE_STYLES
        : ''
    );
