export const CSS_COMPOSER = `.root {
    display: flex;
    flex-direction: %mainFlexDirection%;
    width: 100vw;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.widget-holder {
    position: relative;
    flex-grow: 1;
}
%publishStyles%%subscribeStyles%%listVisuals%%listDashboards%%customizeActionButtons%`;

export const HTML_COMPOSER = `<script
    data-name="composer-embed-manager"
    src="${process.env.REACT_APP_COMPOSER_URL}/embed/embed.js"
>
</script>
<div class="root">%publishCode%%subscribeCode%%listVisuals%%listDashboards%
    <div
        class="widget-holder"
        id="widget-holder"
    ></div>%createSecondDashboard%%customizeActionButtons%
</div>
`;

export const JS_COMPOSER = `const getToken = async () => {
    const response = await fetch('${process.env.REACT_APP_API_TOKEN_URL}', {
        method: 'GET',
        credentials: 'same-origin'
    });
    return response.json().then((result) => {
        return {
            access_token: result.dataDiscoveryToken,
            expires_in: result.expiresIn,
        };
    });
};

%customCellRenderer%

const embedManagerPromise = window.initComposerEmbedManager({ getToken: getToken });

%componentConfig%
%componentCleanup1%
const createEmbedComponent = (embedManager, config, containerElementId = 'widget-holder') => {
    return embedManager.createComponent(%componentType%, config).then(component => {%componentCleanup2%
        component.render(document.getElementById(containerElementId), { width:"100%", height: "100%" });%dbEventListener%%visualEventListener%%dbVisualBuilderReady%%dbVisualBuilderLoad%%dbVisualBuilderFail%%dbVisualBuilderChange%%dashboardBuilderUnauthorized%%dashboardBuilderDocumentFailed%%dashboardBuilderVisualSaveEventListener%%dashboardBuilderVisualRenderEventListener%%dashboardBuilderVisualLoadFailEventListener%%dashboardBuilderVisualLoadEventListener%%dashboardBuilderVisualRemoveEventListener%%dashboardBuilderVisualAddEventListener%%dashboardBuilderSaveEventListener%%dashboardBuilderLoadFailEventListener%%dashboardBuilderDeleteEventListener%%dashboardBuilderChangedEventListener%%dashboardBuilderReadyEventListener%%inventoryDeleteEventListener%%inventoryFailEventListener%%inventoryReadyEventListener%%inventoryEventListeners%%vbVisualBuilderReady%%vbVisualBuilderFail%%vbVisualBuilderChange%%vbVisualSaveEventListener%%vbVisualRenderEventListener%%vbVisualLoadFailEventListener%%vbVisualLoadEventListener%%dashboardEventListeners%%dashboardChangedEventListener%%dashboardDeleteEventListener%%dashboardLoadFailEventListener%%dashboardSaveEventListener%%dashboardVisualAddEventListener%%dashboardVisualRemoveEventListener%%visualLoadFailEventListener%%visualRenderEventListener%%visualSaveEventListener%%dashboardReadyEventListener%%unauthorized%%visualLoadEventListener%%documentFailed%%visualBuilderChange%%visualBuilderFail%%visualBuilderLoad%%visualBuilderReady%%pubSubCommonJsCode%%subscribeCode%%publishCode%%sourceEditorEventListeners%
    })
}
%listVisuals%%listDashboards%%createComponent%%customizeActionButtonsHelpers%`;
